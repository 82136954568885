import React from 'react'
import PlaceIcon from '@material-ui/icons/Place';
import headingbullet from '../../../images/righthand.jpg'


function AdverbExamples() {
    return (

        <div class="section-outer-box bg-lightgray">
            <div class="label-heading">
                <img src={headingbullet}></img> Examples of Conjunctions
            </div>
            <div class="Content_desc">
                <p>Adjectives are used describe a noun. Adjectives are used describe a noun. Adjectives are used describe a noun. Adjectives are used describe a noun. Adjectives are used describe a noun. Adjectives are used describe a noun. Adjectives are used describe a noun. Adjectives are used describe a noun. Adjectives are used describe a noun. Adjectives are used describe a noun. Adjectives are used describe a noun. </p>
            </div>

            <div class="section-inner-box">


            </div>









        </div>


    )
}
export default AdverbExamples
