import React from 'react'
import Grid from '@material-ui/core/Grid';

function Footer() {
    return (
        <div class="Footer-main-box">
            
            <div class="Footer-inner-box">
                <Grid container>
                    <Grid item xs={12} sm={12} >
                        <div>
                            This is developed by Cleverly English IT team
                        </div>
                    </Grid>
                    
                </Grid>
            </div>
            





        </div>
    )
}

export default Footer